<script>
export default {
  data () {
    return {
      schema: [
        { name: 'label', label: 'Nazwa', rules: { required: true, regex: /^[a-zA-ZęóąśłżźćńÓŚŁŻŹĆ\s]{1,}$/ } },
        { name: 'infoText', label: 'Treść', rules: { required: true } }
      ]
    }
  }
}
</script>
