import Grid from '../../../share/grid/Grid.vue'
import Page from '../../../../share/content/Page'
import NewMaintenanceInfo from './modal/NewMaintenanceInfo.vue'

export default {
  template: `
    <div>
        <section class="content">
            <div class="row">
                <div class="col-sm-12">
                    <div class="box box-default box-solid">
                        <div class="box-body table-responsive">
                            <grid :source="gridDataSource"
                                  :name="gridName"
                                  :columns="gridColumns"
                                  :actions="gridActions"
                                  @show="actionShow">
                            </grid>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <NewMaintenanceInfo :show="modalNewMaintenanceInfoVisible"
                    @close="modalNewMaintenanceInfoVisible = false"
                    mode="new"
                    :service="service">
        </NewMaintenanceInfo>
    </div>`,
  components: {
    Grid,
    NewMaintenanceInfo
  },
  mixins: [
    Page
  ],
  data () {
    return {
      events: {
        addMaintenanceInfo: 'core:maintenanceInfo:addMaintenanceInfo'
      },
      gridColumns: [
        { id: 0, data: 'label', title: 'Nazwa' },
        { id: 1,
          data: 'infoType',
          title: 'Typ',
          render (data) {
            let typeMap = {
              1: 'Informacja',
              2: 'Ostrzeżenie'
            }
            return typeMap[data]
          }
        },
        { id: 2, data: 'roles', title: 'Role' }
      ],
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'folder-open', acl: { security: false } }
      ],
      modalNewMaintenanceInfoVisible: false
    }
  },
  mounted () {
    this.$events.on(this.events.addMaintenanceInfo, this.addMaintenanceInfo)
  },
  computed: {
    gridDataSource () {
      const vm = this
      return { service: vm.service, url: '/maintenance-info' }
    }
  },
  methods: {
    addMaintenanceInfo () {
      this.modalNewMaintenanceInfoVisible = true
    },
    actionShow (id) {
      const vm = this
      this.$router.push({ name: vm.actionShowUrlName, params: { id } })
    }
  }
}
