<template>
  <form>
    <input-type
      :key="schema[0].name"
      v-model="value[schema[0].name]"
      v-validate="{rules: schema[0].rules}"
      :data-vv-value-path="'value'"
      :data-vv-as="schema[0].label2 ? schema[0].label2 : schema[0].label"
      :error-message="vErrors.first(schema[0].name)"
      :label="schema[0].label"
      :name="schema[0].name"
      :required="schema[0].rules.required"
    />
    <text-area-type
      :key="schema[1].name"
      v-model="value[schema[1].name]"
      v-validate="{rules: schema[1].rules}"
      :data-vv-value-path="'value'"
      :data-vv-as="schema[1].label2 ? schema[1].label2 : schema[1].label"
      :error-message="vErrors.first(schema[1].name)"
      :label="schema[1].label"
      :name="schema[1].name"
      :required="schema[1].rules.required"
    />
    <vue-multiselect
      v-model="value['infoType']"
      :options="types"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="true"
      :preserve-search="true"
      placeholder="Wybierz typ informacji"
      label="label"
      selected-label="Wybrano"
      select-label="Wybierz"
      deselect-label="Odznacz"
      track-by="label"
      :preselect-first="false"
    />
    <vue-multiselect
      v-model="value['roles']"
      :options="roles"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="true"
      :preserve-search="true"
      placeholder="Wybierz role"
      label="label"
      selected-label="Wybrano"
      select-label="Wybierz"
      deselect-label="Odznacz"
      track-by="label"
      :preselect-first="false"
    />
  </form>
</template>

<script>
import FormModalValidation from '../../../../../share/form/FormModalValidation'
import InputType from '../../../../../share/form/type/InputType'
import Schema from './Schema'
import VueMultiselect from 'vue-multiselect/src/Multiselect'
import api from '../../../../../../api'
import TextAreaType from '../../../../../share/form/type/TextAreaType'

export default {
  components: {
    TextAreaType,
    VueMultiselect,
    InputType
  },
  mixins: [
    Schema,
    FormModalValidation
  ],
  props: {
    value: {}
  },
  data () {
    return {
      service: 'core',
      roles: [],
      types: []
    }
  },
  mounted () {
    this.getRoles()
    this.getTypes()
  },
  methods: {
    getRoles () {
      api.request(this.service, 'get', `/roles`)
        .then((request) => {
          this.roles = request.data
        })
    },
    getTypes () {
      this.types = [
        { value: 1, label: 'Informacja' },
        { value: 2, label: 'Ostrzeżenie' }
      ]
    }
  }
}
</script>
