<script>
import BaseListing from './Listing'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      gridName: 'grid_maintenance_info',
      headerTitle: { title: 'Komunikaty administracyjne', description: 'Lista' },
      service: 'core',
      actionShowUrlName: 'admin_maintenance_info',
      gridActions: [
        { id: 0, event: 'show', title: 'Szczegóły', icon: 'eye', acl: { security: false } }
      ]
    }
  }
}
</script>
