<script>
import FormModalMixin from '../../../../../share/modal/FormModalMixin'
import FormModal from '../form/NewMaintenanceInfo'
import api from '../../../../../../api'

export default {
  components: {
    FormModal
  },
  mixins: [
    FormModalMixin
  ],
  props: {
    mode: { type: String, required: true },
    service: { type: String, required: true }
  },
  data () {
    return {
      events: {
        newPoliceUnitModal: 'core:maintenanceInfo:newMaintenanceInfo'
      },
      title: 'Dodanie komunikatu administracyjnego',
      method: { new: 'post' },
      action: { new: '/maintenance-info' }
    }
  },
  methods: {
    submitForm () {
      api.request(this.service, this.method[this.mode], this.action[this.mode], this.createDataToSend())
        .then(() => {
          this.toggleLoading()

          this.$events.$emit('reloadGrid')
          this.$emit('close')
          this.$notify({
            type: 'success',
            text: 'Dodano komunikat administracyjny'
          })
        })
        .catch((error) => {
          this.toggleLoading()
          this.errorNotify(error, 'error', 'Wystąpił błąd', 'Nie można dodać komunikatu administracyjnego.')
        })
    },
    createDataToSend () {
      return {
        label: this.model.label,
        infoText: this.model.infoText,
        infoType: this.model.infoType.value,
        roles: this.model.roles.length > 0 && this.model.roles.map(role => role.id)
      }
    }
  }
}
</script>
